<template>
  <div id="CartTable">
    <b-table
      v-if="carts"
      striped
      hoverable
      :data="carts"
      @click="navigateToCartDetail"
      style="cursor: pointer;"
    >
      <template slot-scope="props">
        <b-table-column field="id" label="Order ID">
          {{ props.row.order_id }}
        </b-table-column>

        <b-table-column field="date" label="Created Date">
          {{ props.row.create_time | dateformatter }}
        </b-table-column>

        <b-table-column field="date" label="Update Date">
          <div v-if="props.row.update_time">
            {{ props.row.update_time | dateformatter }}
          </div>
          <div v-else>-</div>
        </b-table-column>

        <b-table-column field="date" label="Payment Date">
          <div v-if="props.row.payment_time">
            {{ props.row.payment_time | dateformatter }}
          </div>
          <div v-else>-</div>
        </b-table-column>

        <b-table-column field="type" label="Type" centered>
          <span v-if="props.row.status === 'paid'" class="tag is-warning">
            PAID
          </span>
          <span v-else-if="props.row.status === 'done'" class="tag is-success">
            COMPLETED
          </span>
          <span v-else-if="props.row.status === 'open'" class="tag is-info">
            OPEN
          </span>
          <span
            v-else-if="props.row.status === 'need_payment'"
            class="tag is-danger"
          >
            UNPAID
          </span>
          <span v-else class="tag is-danger">
            {{ props.row.status }}
          </span>
        </b-table-column>
      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import Helpers from "@/utils/Helpers";
import CartApi from "@/apis/CartApi";

export default {
  name: "CartTable",
  props: ["filter"],
  data() {
    return {
      carts: null
    };
  },
  watch: {
    filter: function(val) {
      if (val) {
        this.getFilteredCartList(val);
      }
    }
  },
  filters: {
    dateformatter: function(value) {
      if (!value) return "";
      return Helpers.dateFormatter(value);
    }
  },
  methods: {
    getFilteredCartList: async function(option) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await CartApi.requestCartList(option);
      loadingComponent.close();
      if (response && response.length > 0) {
        this.carts = response;
      } else {
        this.carts = [];
      }
    },
    navigateToCartDetail: function(selectedCart) {
      this.$router.push({
        name: "cart-detail",
        params: { cart: selectedCart }
      });
    }
  },
  mounted() {
    this.getFilteredCartList("paid");
  }
};
</script>

<style scoped>
.cover-center {
  object-position: center;
  object-fit: cover;
  height: 128px;
}
.clickable-icon {
  cursor: pointer;
  margin-right: 0.5rem;
}
</style>
