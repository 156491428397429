<template>
  <div id="CartManage" class="section">
    <div class="columns is-multiline">
      <div class="column is-10">
        <h1 class="title">
          CART MANAGER
        </h1>
      </div>
      <div class="column is-2">
        <b-select
          expanded
          v-model="filterOption"
          placeholder="Select a character"
        >
          <option value="all">All</option>
          <option value="done">Completed</option>
          <option value="open">Open</option>
          <option value="paid">Paid</option>
          <option value="need_payment">Unpaid</option>
        </b-select>
      </div>
    </div>

    <div class="container">
      <cart-table :filter="filterOption" />
    </div>
  </div>
</template>

<script>
import CartTable from "@/components/tables/CartTable";

export default {
  name: "CartManage",
  components: {
    CartTable
  },
  data() {
    return {
      filterOption: "paid"
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#CartManage {
  text-align: left;
}
</style>
